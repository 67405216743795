<script lang="ts">
import idPageInit from '~/packages/datakode/nuxt-datakode-utils/utils/idPageInit.vue'

export default {
    extends: idPageInit,
    methods: {
        launchSave() {
            if (this.fetchedData)
                this.save()
        },
    },
}
</script>

<template>
    <ModalMiddle
        ref="modalParent"
        :max-width="800"
        :saving="saving"
        :save-text="isCreatePath ? 'Ajouter l\'utilisateur' : 'Modifier l\'utilisateur'"
        custom-button-color="primary"
        :custom-button-block="true"
        :default-url="defaultUrl"
        :title-modal="titleModal"
        :is-form="isForm"
        :data="fetchedData"

        :hide-top-buttons="true"
        :hide-default-actions="!isForm"
        @save="launchSave()"
        @close-modal="closeModal"
    >
        <!-- CLEAR APP_ROLES FROM DENY VALUE -->
        <template #customContentTop>
            <v-btn
                v-if="$permissionCheck(`${permissionName}_update`) && !isForm && meta?.editable"
                prepend-icon="mdi-square-edit-outline"
                class="mb-4"
                color="primary"
                size="small"
                @click.prevent="$refs.modalParent?.redirectEdit()"
            >
                Modifier
            </v-btn>
        </template>
        <template #content>
            <v-form
                ref="form"
                fast-fail
                @submit.prevent
            >
                <NuxtPage
                    ref="display"
                    :loading="loading"
                    :data="fetchedData"
                    :is-form="isForm"
                    :rules="rules"
                    :translation-name="translationName"
                />
            </v-form>
        </template>
    </ModalMiddle>
</template>
