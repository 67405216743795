import revive_payload_client_WoYFLSIC1A from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fKNLavx9Ux from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xjdGKVOsAU from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Otzpaa0e2i from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_r1RoD5hq7E from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JyDwyazHZF from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_y2dOLIRGcW from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_31JSsjklEn from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.2_typescript@5.4.4_vue@3.5.4_typescript@5.4.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/datakode/pildom/APPS/NUXT_ADMIN/.nuxt/components.plugin.mjs";
import prefetch_client_io9rIGzAF4 from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.31_encoding@0.1.13_eslint@8.57.0_ioredis@_fyoktb4uehdb3wneofzl37qfge/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_GiYgGPWVxm from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.4.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_BeJ4V4sp8o from "/builds/datakode/pildom/APPS/NUXT_ADMIN/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.4.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import mergeObjects_JbHzNSpMmN from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/plugins/mergeObjects.ts";
import vuetify_DPVY7aMxlt from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/plugins/vuetify.ts";
import notifier_TfOkCLHRTj from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/notifier.ts";
import api_client_t0dWRHSNDZ from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/api.client.ts";
import currentPath_yqNfcRq2j9 from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/currentPath.ts";
import currentUser_28J1aqNENu from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/currentUser.ts";
import translation_NgY8VMsLMG from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/translation.ts";
import getColor_Ghj8WPsFjF from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/getColor.ts";
import helper_Bdxgb2x3zG from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/helper.ts";
import formatDate_za3K2nEobv from "/builds/datakode/pildom/APPS/NUXT_ADMIN/packages/datakode/nuxt-datakode-utils/plugins/formatDate.ts";
import leaflet_client_7dEOJA4hbk from "/builds/datakode/pildom/APPS/NUXT_ADMIN/plugins/leaflet.client.ts";
export default [
  revive_payload_client_WoYFLSIC1A,
  unhead_fKNLavx9Ux,
  router_xjdGKVOsAU,
  payload_client_Otzpaa0e2i,
  navigation_repaint_client_r1RoD5hq7E,
  check_outdated_build_client_JyDwyazHZF,
  chunk_reload_client_y2dOLIRGcW,
  plugin_vue3_31JSsjklEn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_io9rIGzAF4,
  switch_locale_path_ssr_GiYgGPWVxm,
  i18n_BeJ4V4sp8o,
  mergeObjects_JbHzNSpMmN,
  vuetify_DPVY7aMxlt,
  notifier_TfOkCLHRTj,
  api_client_t0dWRHSNDZ,
  currentPath_yqNfcRq2j9,
  currentUser_28J1aqNENu,
  translation_NgY8VMsLMG,
  getColor_Ghj8WPsFjF,
  helper_Bdxgb2x3zG,
  formatDate_za3K2nEobv,
  leaflet_client_7dEOJA4hbk
]