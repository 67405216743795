import { default as formulaireMYWSzqJDqSMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/applications/[k]/formulaire.vue?macro=true";
import { default as _91k_93DvXPlZanRzMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/applications/[k].vue?macro=true";
import { default as applicationsUmEH5MN2FYMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/applications.vue?macro=true";
import { default as connexion4nt7XwuHZaMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/connexion.vue?macro=true";
import { default as formulaireXUdWaR8JjYMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches/[k]/formulaire.vue?macro=true";
import { default as indexubd9vXZTVdMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches/[k]/index.vue?macro=true";
import { default as _91k_93jph1571B1aMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches/[k].vue?macro=true";
import { default as couchesbiGCefA8cVMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches.vue?macro=true";
import { default as demandes_45accesCk117YDs1FMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/demandes-acces.vue?macro=true";
import { default as donnees_45personnellespaurNKrvnMMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/donnees-personnelles.vue?macro=true";
import { default as index4WdmYIGEnYMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/index.vue?macro=true";
import { default as mentions_45legalesBkHKSQ0SPqMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mentions-legales.vue?macro=true";
import { default as _91token_931IrIo3PgeLMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue?macro=true";
import { default as index2M1A8M8GpqMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieQ59Pfa9u9QMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie.vue?macro=true";
import { default as indexfTC0yZvREtMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil/index.vue?macro=true";
import { default as profilz2qLtBf3MfMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil.vue?macro=true";
import { default as formulaire424LC88yKMMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/formulaire.vue?macro=true";
import { default as index1gKrGaf5rDMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/index.vue?macro=true";
import { default as _91k_93haBITFByqUMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures/[k].vue?macro=true";
import { default as structuresDH8j6SLsAIMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures.vue?macro=true";
import { default as formulaire6up0ddJq1HMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexdnOpYXg6WeMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93OzSY3U499PMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k].vue?macro=true";
import { default as utilisateursfy8uaB16jPMeta } from "/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs.vue?macro=true";
export default [
  {
    name: "applications",
    path: "/applications",
    meta: applicationsUmEH5MN2FYMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/applications.vue").then(m => m.default || m),
    children: [
  {
    name: "applications-k",
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/applications/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "applications-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/applications/[k]/formulaire.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: "couches",
    path: "/couches",
    meta: couchesbiGCefA8cVMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93jph1571B1aMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "couches-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "couches-k",
    path: "",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/couches/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "demandes-acces",
    path: "/demandes-acces",
    meta: demandes_45accesCk117YDs1FMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/demandes-acces.vue").then(m => m.default || m)
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/donnees-personnelles.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: mot_45de_45passe_45oublieQ59Pfa9u9QMeta?.name,
    path: "/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieQ59Pfa9u9QMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie.vue").then(m => m.default || m),
    children: [
  {
    name: "mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_931IrIo3PgeLMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/[token].vue").then(m => m.default || m)
  },
  {
    name: "mot-de-passe-oublie",
    path: "",
    meta: index2M1A8M8GpqMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/mot-de-passe-oublie/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profilz2qLtBf3MfMeta?.name,
    path: "/profil",
    meta: profilz2qLtBf3MfMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil.vue").then(m => m.default || m),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/NUXT_COMMON/pages/profil/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "structures",
    path: "/structures",
    meta: structuresDH8j6SLsAIMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93haBITFByqUMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "structures-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "structures-k",
    path: "",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/structures/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "utilisateurs",
    path: "/utilisateurs",
    meta: utilisateursfy8uaB16jPMeta || {},
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs.vue").then(m => m.default || m),
    children: [
  {
    name: _91k_93OzSY3U499PMeta?.name,
    path: ":k()",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k].vue").then(m => m.default || m),
    children: [
  {
    name: "utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/formulaire.vue").then(m => m.default || m)
  },
  {
    name: "utilisateurs-k",
    path: "",
    component: () => import("/builds/datakode/pildom/APPS/NUXT_ADMIN/pages/utilisateurs/[k]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]